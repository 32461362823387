export default [
  {
    name: 'English',
    value: 'en'
  },
  {
    name: 'German',
    value: 'de'
  },
  {
    name: 'Italian',
    value: 'it'
  },
  {
    name: 'French',
    value: 'fr'
  },
  {
    name: 'Spanish',
    value: 'es'
  },
  {
    name: 'Russian',
    value: 'ru'
  },
  {
    name: 'Dutch',
    value: 'nl'
  },
  {
    name: 'Norwegian',
    value: 'no'
  },
  {
    name: 'Swedish',
    value: 'sv'
  },
  {
    name: 'Danish',
    value: 'da'
  },
  // {
  //   name: 'Afrikaans',
  //   value: 'af'
  // },
  // {
  //   name: 'Arabic, Modern Standard',
  //   value: 'ar'
  // },
  // {
  //   name: 'Armenian',
  //   value: 'hy'
  // },
  // {
  //   name: 'Basque',
  //   value: 'eu'
  // },
  // {
  //   name: 'Bengali',
  //   value: 'bn'
  // },
  // {
  //   name: 'Breton',
  //   value: 'br'
  // },
  // {
  //   name: 'Bulgarian',
  //   value: 'bg'
  // },
  // {
  //   name: 'Catalan',
  //   value: 'ca'
  // },
  // {
  //   name: 'Chinese Simplified',
  //   value: 'zh'
  // },
  // {
  //   name: 'Croatian',
  //   value: 'hr'
  // },
  // {
  //   name: 'Czech',
  //   value: 'cs'
  // },
  // {
  //   name: 'Danish',
  //   value: 'da'
  // },
  // {
  //   name: 'Dutch',
  //   value: 'nl'
  // },
  // {
  //   name: 'Esperanto',
  //   value: 'eo'
  // },
  // {
  //   name: 'Estonian',
  //   value: 'et'
  // },
  // {
  //   name: 'Farsi',
  //   value: 'fa'
  // },
  // {
  //   name: 'Finnish',
  //   value: 'fi'
  // },
  // {
  //   name: 'Galician',
  //   value: 'gl'
  // },
  // {
  //   name: 'Greek',
  //   value: 'el'
  // },
  // {
  //   name: 'Hausa',
  //   value: 'ha'
  // },
  // {
  //   name: 'Hebrew',
  //   value: 'he'
  // },
  // {
  //   name: 'Hindi',
  //   value: 'hi'
  // },
  // {
  //   name: 'Hungarian',
  //   value: 'hu'
  // },
  // {
  //   name: 'Indonesian',
  //   value: 'id'
  // },
  // {
  //   name: 'Irish',
  //   value: 'ga'
  // },
  // {
  //   name: 'Japanese',
  //   value: 'ja'
  // },
  // {
  //   name: 'Korean',
  //   value: 'ko'
  // },
  // {
  //   name: 'Latin',
  //   value: 'la'
  // },
  // {
  //   name: 'Latvian',
  //   value: 'lv'
  // },
  // {
  //   name: 'Lugbara',
  //   value: 'lgg'
  // },
  // {
  //   name: 'Lugbara official',
  //   value: 'lggo'
  // },
  // {
  //   name: 'Marathi',
  //   value: 'mr'
  // },
  // {
  //   name: 'Myanmar',
  //   value: 'my'
  // },
  // {
  //   name: 'Norwegian',
  //   value: 'no'
  // },
  // {
  //   name: 'Polish',
  //   value: 'pl'
  // },
  // {
  //   name: 'Portuguese',
  //   value: 'pt'
  // },
  // {
  //   name: 'Portuguese (Brazilian',
  //   value: 'ptbr'
  // },
  // {
  //   name: 'Punjabi Gurmukhi',
  //   value: 'pa'
  // },
  // {
  //   name: 'Romanian',
  //   value: 'ro'
  // },
  // {
  //   name: 'Slovak',
  //   value: 'sk'
  // },
  // {
  //   name: 'Slovenian',
  //   value: 'sl'
  // },
  // {
  //   name: 'Somali',
  //   value: 'so'
  // },
  // {
  //   name: 'Sotho',
  //   value: 'st'
  // },
  // {
  //   name: 'Swahili',
  //   value: 'sw'
  // },
  // {
  //   name: 'Swedish',
  //   value: 'sv'
  // },
  // {
  //   name: 'Thai',
  //   value: 'th'
  // },
  // {
  //   name: 'Tagalog (Filipino)',
  //   value: 'tl'
  // },
  // {
  //   name: 'Turkish',
  //   value: 'tr'
  // },
  // {
  //   name: 'Urdu',
  //   value: 'ur'
  // },
  // {
  //   name: 'Vietnamese',
  //   value: 'vi'
  // },
  // {
  //   name: 'Yoruba',
  //   value: 'yo'
  // },
  // {
  //   name: 'Zulu',
  //   value: 'zu'
  // }
];
