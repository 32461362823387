<template>
  <div class="article-create block">
    <div class="article-create-header block-wrapper header">
      <h1 class="article-create-title">Add new articles</h1>
      <p class="article-create-description">You can enter text manually</p>
    </div>
    <div class="article-create-content block-wrapper editor">
      <Preloader v-if="pending"/>
      <div class="article-create-title">
        <Input v-model.trim="$v.title.$model" @input="countSymbols" label="Meta title" placeholder="All meta title"/>
        <div class="article-create-title-symbols-container">
          <p class="article-create-title-symbols">symbols: {{titleSymbols}}</p>
        </div>
      </div>
      <Editor v-model="text"/>
      <div class="article-create-footer df">
        <div class="article-create-selects df">
          <Dropdown
            label="Select text language:"
            :list="listLang"
            :active="lang" @selected="selectLang"
          />
          <!--          <Checkbox v-model="checkArticle" text="Dont check TEXT.RU & CONTENT-WATCH.RU" />-->
        </div>
        <div class="article-create-buttons df">
          <button class="btn blue" @click="submit" :disabled="$v.$invalid">Add text</button>
          <!--          <button class="btn green">Add text & keep adding</button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';
import Preloader from '@/components/common/Preloader';
import languages from '@/utils/languages';

export default {
  name: "ArticlesCreate",
  components: {
    Preloader,
    Input: () => import('@/components/common/AInput'),
    Editor: () => import('@/components/common/AEditor'),
    Dropdown: () => import('@/components/common/ADropdown'),
    // Checkbox: () => import('@/components/common/ACheckbox')
  },
  mixins: [validationMixin],
  validations: {
    title: {
      required
    }
  },
  data() {
    return {
      titleSymbols: 0,
      listLang: languages,
      lang: languages.find(l => l.value === 'en'),
      // checkArticle: true,
      title: '',
      text: '',
      pending: false
    }
  },
  computed: {
    ...mapGetters(['user', 'prevUser']),
  },
  methods: {
    ...mapActions(['createArticle']),
    selectLang(lang) {
      this.lang = lang;
    },
    countSymbols(text) {
      this.titleSymbols = text.trim().length
    },
    async submit() {
      this.pending = true;
      await this.createArticle({
        title: this.title,
        text: this.text,
        user_id: this.prevUser.id !== null ? this.user.id : null,
        language: this.lang.value
      }).then(data => {
        this.pending = false;
        this.$router.push(`/dashboard/articles/${data.text_id}`)
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Create article',
          text: error.response.data.message,
          type: 'error'
        });
      });
    }
  }
}
</script>

<style scoped>

</style>
